import { gsap } from 'gsap';

document.addEventListener('DOMContentLoaded', function() {
  const sponsorSliderContainer = document.querySelector('.wp-block-sponsor-slider');
  if (!sponsorSliderContainer) return; // Exit if the container doesn't exist

  const slider = sponsorSliderContainer.querySelector('.sponsor-slider');
  const slides = Array.from(slider.querySelectorAll('.sponsor'));
  let animation;
  let isReversed = false;
  let isPaused = false;

  const cloneSlides = (count) => {
    for (let i = 0; i < count; i++) {
      slides.forEach(slide => {
        const clone = slide.cloneNode(true);
        slider.appendChild(clone);
      });
    }
  };

  const getTotalWidth = () => {
    return Array.from(slider.querySelectorAll('.sponsor')).reduce((acc, slide) => acc + slide.offsetWidth, 0);
  };

  const animateSlider = () => {
    const totalWidth = getTotalWidth();

    if (animation) {
      animation.kill();
    }

    gsap.set(slider, { x: 0 });

    animation = gsap.to(slider, {
      x: -totalWidth,
      duration: totalWidth / 50,
      ease: 'linear',
      repeat: -1,
      modifiers: {
        x: gsap.utils.unitize(value => parseFloat(value) % totalWidth)
      },
      paused: false,
    });
  };

  // Initially clone slides enough to cover at least twice the container width
  const containerWidth = sponsorSliderContainer.offsetWidth;
  const singleSlideWidth = slides[0].offsetWidth;
  const clonesNeeded = Math.ceil((containerWidth * 2) / singleSlideWidth);

  cloneSlides(clonesNeeded);

  const playButton = sponsorSliderContainer.querySelector('.sponsor-slider__play');
  const pauseButton = sponsorSliderContainer.querySelector('.sponsor-slider__pause');
  const reverseButton = sponsorSliderContainer.querySelector('.sponsor-slider__reverse');

  const updateButtonStates = () => {
    playButton.disabled = !isPaused && !isReversed;
    reverseButton.disabled = !isPaused && isReversed;
    pauseButton.disabled = isPaused;

    playButton.classList.toggle('active', !isPaused && !isReversed);
    reverseButton.classList.toggle('active', !isPaused && isReversed);
    pauseButton.classList.toggle('active', isPaused);
  };

  playButton.addEventListener('click', (e) => {
    e.stopPropagation();
    if (isPaused || isReversed) {
      isReversed = false;
      isPaused = false;
      animation.play();
      updateButtonStates();
    }
  });

  pauseButton.addEventListener('click', (e) => {
    e.stopPropagation();
    if (!isPaused) {
      isPaused = true;
      animation.pause();
      updateButtonStates();
    }
  });

  reverseButton.addEventListener('click', (e) => {
    e.stopPropagation();
    if (isPaused || !isReversed) {
      isReversed = true;
      isPaused = false;
      animation.reverse();
      updateButtonStates();
    }
  });

  const handleResize = () => {
    const currentProgress = animation ? animation.progress() : 0;
    const currentTimeScale = animation ? animation.timeScale() : 1;
    animateSlider();
    if (!isPaused) {
      animation.progress(currentProgress);
      animation.timeScale(currentTimeScale * (isReversed ? -1 : 1));
      animation.play();
    }
    updateButtonStates();
  };

  animateSlider();
  updateButtonStates();

  window.addEventListener('resize', handleResize);
});
